<template>
  <v-card id="invoice-list">
    <!-- search -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">
        <!-- actions -->
        <v-select
          v-model="selectedAction"
          label="Actions"
          single-line
          outlined
          dense
          :items="actions"
          hide-details
          :disabled="Boolean(!selectedTableData.length)"
          class="invoice-list-actions me-3"
        ></v-select>
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">
        <v-text-field
          v-model="searchQuery"
          single-line
          dense
          outlined
          hide-details
          placeholder="Search ID/Num"
          class="invoice-list-search me-3"
        ></v-text-field>

        <v-text-field
          v-model="emailQuery"
          single-line
          dense
          outlined
          hide-details
          placeholder="User email"
          class="invoice-list-search me-3"
        ></v-text-field>

        <v-select
          v-model="statusFilter"
          :items="statusOptions"
          single-line
          outlined
          dense
          hide-details
          clearable
          placeholder="Select Status"
          class="invoice-list-status"
        ></v-select>
      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedTableData"
      :headers="tableColumns"
      :items="invoiceListTable"
      :options.sync="options"
      :server-items-length="totalInvoiceListTable"
      :loading="loading"
      class="text-no-wrap"
    >
      <!-- trending header -->
      <template #[`header.trending`]>
        <v-icon size="22">
          {{ icons.mdiTrendingUp }}
        </v-icon>
      </template>

      <!-- id -->
      <template #[`item.id`]="{item}">
        <router-link
          class="font-weight-medium text-decoration-none"
          :to="{ name: 'order-view', params: { id: item.id } }"
        >
          {{ item.order_number }}
        </router-link>
        
        <div v-if="searchQuery">
          <small>{{ item.checkout.receipt_number }} | {{ item.checkout.custom_id }}</small>
        </div>
      </template>

      <!-- user -->
      <template #[`item.user`]="{item}">
        <span
          class="font-weight-medium text-decoration-none"
          :to="{ name: 'channel-user-view', params: { id: item.user_id } }"
        >
          {{ item.user.email }}
        </span>
      </template>

      <!-- merchant -->
      <template #[`item.merchant`]="{item}">
        <!-- <router-link
          class="font-weight-medium text-decoration-none"
          :to="{ name: 'checkout-view', params: { id: item.id } }"
        > -->
        {{ t(item.merchant.name) }}
        <!-- </router-link> -->
      </template>

      <!-- total -->
      <template #[`item.total`]="{item}">
        {{ item.total | price }}
        <span v-if="item.checkout.total !== item.total">(${{ item.checkout.total }})</span>
      </template>

      <!-- Status -->
      <template #[`item.status`]="{item}">
        <!-- chip -->
        <v-chip
          small
          :color="statusColor(item, item.checkout)"
          :class="`v-chip-light-bg ${statusColor(item, item.checkout)}--text font-weight-semibold`"
        >
          {{ displayStatusText(item, item.checkout) }}
        </v-chip>
        - {{ item.status }}
      </template>

      <!-- date -->
      <template #[`item.created_at`]="{item}">
        <span class="text-no-wrap">{{ formatDate(item.created_at) }}</span>
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">
          <!-- view  -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                :to="{ name: 'order-view', params: { id: item.id } }"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiEyeOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>View Order</span>
          </v-tooltip>

          <!-- dropdown -->
          <!-- <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(option, i) in actionOptions"
                :key="i"
                href="javascript:void(0)"
              >
                <v-list-item-title>
                  <v-icon
                    size="18"
                    class="me-2"
                  >
                    {{ option.icon }}
                  </v-icon>
                  <span>{{ option.title }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu> -->
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiDeleteOutline, mdiDotsVertical, mdiEyeOutline, mdiPlus, mdiReceipt, mdiTrendingUp } from '@mdi/js'

import { t } from '@/plugins/i18n'
import { avatarText, displayStatusText, formatDate, price, statusColor } from '@core/utils/filter'
import { ref } from '@vue/composition-api'

// composition function
import useOrderList from './useOrderList'

export default {
  filters: { price },
  setup() {
    const { invoiceListTable, searchQuery, emailQuery, tableColumns, options, statusFilter, totalInvoiceListTable, loading, selectedTableData } = useOrderList()

    const statusOptions = ref(['wait_for_payment', 'expired', 'cancelled', 'wait_for_confirmation', 'confirmed', 'completed'])

    const actions = ['Delete', 'Edit', 'Send']

    const selectedAction = ref('')

    const actionOptions = [
      // { title: 'Download', icon: mdiDownloadOutline },
      // { title: 'Edit', icon: mdiPencilOutline },
      // { title: 'Delete', icon: mdiDeleteOutline },
      // { title: 'Duplicate', icon: mdiContentCopy },
    ]

    const checkType = data => {
      if (typeof data === 'number') return 'number'
      if (typeof data === 'string') return 'string'

      return 0
    }

    return {
      tableColumns,
      searchQuery,
      emailQuery,
      statusOptions,
      statusFilter,
      options,
      totalInvoiceListTable,
      invoiceListTable,
      loading,
      actions,
      selectedTableData,
      actionOptions,
      selectedAction,

      checkType,
      avatarText,
      formatDate,

      t,
      displayStatusText,
      statusColor,

      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiReceipt,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
#invoice-list {
  .invoice-list-actions {
    max-width: 7.81rem;
  }
  .invoice-list-search {
    max-width: 10.625rem;
  }
  .invoice-list-status {
    max-width: 11.3rem;
  }
}
</style>
