import { withOptions } from '@/assets/js/utils'
import { fetchOrders } from '@api/order'
import { ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default function useInvoicesList() {
  const invoiceListTable = ref([])

  // Table Handlers
  const tableColumns = [
    {
      text: '#ID',
      align: 'start',
      value: 'id',
    },
    { text: 'User', value: 'user', sortable: false },

    // { text: 'Merchant', value: 'merchant', sortable: false },
    { text: 'TOTAL', value: 'total' },
    { text: 'DATE', value: 'created_at' },
    { text: 'STATUS', value: 'status' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
    },
  )
  const searchQuery = ref(cached[1] || '')
  const emailQuery = ref(cached[2] || '')
  const statusFilter = ref(cached[3] || null)
  const totalInvoiceListTable = ref(0)
  const loading = ref(false)
  const selectedTableData = ref([])

  const loadOrders = () =>
    fetchOrders(
      withOptions(options.value, {
        search: searchQuery.value,
        email: emailQuery.value,

        // options: options.value,
        status: statusFilter.value,
      }),
    )
      .then(({ data }) => {
        const { records, pagination } = data.data
        invoiceListTable.value = records
        totalInvoiceListTable.value = pagination.total
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })

  const cachable = [options, searchQuery, emailQuery, statusFilter]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(
    cachable,
    debounce(() => {
      const newPage = options.value.page

      const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

      if (newPage >= 2 && hasDiff) {
        options.value.page = 1
      }

      loading.value = true
      selectedTableData.value = []
      loadOrders()
      cache()
    }, 300),
  )

  return {
    tableColumns,
    searchQuery,
    emailQuery,
    options,
    invoiceListTable,
    statusFilter,
    totalInvoiceListTable,
    loading,
    selectedTableData,
    loadOrders,
  }
}
